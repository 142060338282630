import { getAnalytics, logEvent } from "firebase/analytics";
import { today } from "@/helpers/utils";
import store from "@/store";

// TODO: Refatorar o uso desta função para remover o store, que pode causas bugs quando invocado em métodos de um componente
/**
 * Function to send a click event to Firebase Analytics to see if
 * our users has been using something.
 * @param {Object} params The params such as label and action (key name for an action)
 */
export function logBtnEvent(params = {}) {
  const { position, department, is_google_admin } = store.getters.currentUser;

  const defaultAdditionalLog = {
    main_domain: store.getters.mainDomain,
    position: position || "",
    department: department || "",
    is_google_admin,
  };

  const log = { ...params, ...defaultAdditionalLog };
  const analytics = getAnalytics();
  logEvent(analytics, "btn_click", log);
}

// TODO: Refatorar o uso desta função para utilizar nos logs de meeting
/**
 * register an analytic event to those who schedule a meeting
 * @param {string} main_domain current company main domain
 * @param {string} clicked_where The button the user clicked
 * @returns undefined
 */
export function logScheduleMeetingInAnalytics(main_domain, clicked_where) {
  const analytics = getAnalytics();
  logEvent(analytics, "schedule_meeting", {
    main_domain,
    clicked_where,
  });
}

export function setFirebaseEvent(
  main_domain,
  event_name,
  clicked_where,
  email
) {
  const analytics = getAnalytics();

  logEvent(analytics, event_name, {
    main_domain,
    clicked_where,
    date: today,
    email,
  });
}
